import { graphql, Link } from "gatsby";
import React, { useState } from "react";
import PortableText from "react-portable-text";
import Container from "../components/Container/Container";
import MutedButton from "../components/Global/MutedButton";
import GraphQLErrorList from "../components/graphql-error-list";
import InView from "../components/InView";
import Seo from "../components/SEO/SEO";

import Image from "gatsby-plugin-sanity-image";

// import React Player
import ReactPlayer from "react-player";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// components/something.js
import Sensor from "../components/sensor";

export const query = graphql`
	query SustainabilityPageQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			defaultImage {
				asset {
					_id
				}
			}
		}
		sanitySustainability {
			title
			introduction
			hero {
				kind
				heroImage {
					...Image
					alt
				}
				heroVideoUrl
			}
			content {
				... on SanityFullBleedImage {
					_key
					_type
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
				... on SanityImageTextBlock {
					_key
					_type
					description
					imageDirection
					imageType
					layout
					title
					link
					linkText
					media
					videoUrl
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
				... on SanityRelatedNews {
					_key
					_type
					news {
						title
						_rawExcerpt
						mainImage {
							...Image
							alt
							asset {
								_id
							}
						}
						slug {
							current
						}
					}
				}
				... on SanityRelatedProjects {
					_key
					_type
					projects {
						_rawExcerpt
						previewImage {
							...Image
							alt
						}
						slug {
							current
						}
						title
					}
				}
				... on SanityEnvironment {
					_key
					_type
					introduction
					liveable
					liveableTitle
					roofs
					roofsTitle
					solar
					solarTitle
					title
					trees
					treesTitle
					water
					waterTitle
					wheat
					wheatTitle
				}
			}
		}
	}
`;

const Sustainability = (props) => {
	const { data, errors } = props;
	const site = (data || {}).site;
	const [loaded, setLoaded] = useState(false);
	const [muted, setMuted] = useState(true);

	if (errors) {
		return (
			<>
				<GraphQLErrorList errors={errors} />
			</>
		);
	}

	const RelatedProject = ({ item }) => (
		<>
			<div className="col-span-6 md:col-span-3 border-r border-grey-default box-border">
				<div className="pb-10 pr-[10px] md:pb-[120px] md:pr-10">
					<Link
						className="overflow-hidden block"
						to={`/project/${item.slug.current}`}
					>
						<Image
							{...item.previewImage}
							width={500}
							alt=" "
							className={
								`transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
								(loaded && " loaded")
							}
							onLoad={() => setLoaded(true)}
						/>
					</Link>
					<h2 className="text-sm mt-3">{item.title}</h2>
				</div>
			</div>
		</>
	);

	return (
		<>
			<Seo
				title={data.sanitySustainability.title}
				description={data.sanitySustainability.description}
				image={
					data.sanitySustainability.hero.heroImage
						? data.sanitySustainability.hero.heroImage
						: site.defaultImage
				}
			/>
			<Container>
				<div className="absolute hidden md:inline-block left-1/2 transform -translate-x-1/2 container top-20 z-10 text-white">
					<h1 className="font-serif text-[32px] leading-[1.2]">
						{data.sanitySustainability.title}
					</h1>
				</div>

				{data.sanitySustainability.hero.kind === "video" && (
					<div
						className={
							`hero-wrapper video-wrapper mb-4 md:mb-0 lazy ` +
							(loaded && " loaded")
						}
					>
						<ReactPlayer
							width="100vw"
							height="100vh"
							playing
							playsinline={true}
							loop
							muted
							url={data.sanitySustainability.hero.heroVideoUrl}
							onStart={() => setLoaded(true)}
						/>
					</div>
				)}

				{data.sanitySustainability.hero.kind === "image" && (
					<div className="hero-wrapper mb-4 md:mb-0">
						<Image
							{...data.sanitySustainability.hero.heroImage}
							width={1600}
							height={880}
							alt={data.sanitySustainability.hero.heroImage.alt}
							className={`lazy ` + (loaded && " loaded")}
							onLoad={() => setLoaded(true)}
						/>
					</div>
				)}

				{data.sanitySustainability.content.length > 0 &&
					data.sanitySustainability.content.map((content, i) => (
						<>
							{content._type === "imageTextBlock" && (
								<InView key={i}>
									<div className="border-b border-grey-default box-border">
										<div className="container flex flex-col md:grid md:grid-cols-12 py-7 md:py-0 md:mt-10 gap-6 md:gap-10">
											<div
												className={`flex flex-col md:pb-10 ${
													content.layout === "video" ? ` md:col-span-4` : ` `
												} ${
													content.layout === "two-thirds"
														? ` md:col-span-5`
														: ` `
												} ${
													content.layout === "one-quarter"
														? ` md:col-span-8`
														: ` `
												}${
													content.imageDirection === "image-right"
														? ` md:pr-10 order-1`
														: ` order-2 mt-4 md:mt-0`
												}`}
											>
												<h3 className="max-w-[350px]">{content.title}</h3>
												<div
													className={`mt-3 md:mt-auto ${
														content.layout === "two-thirds"
															? ` max-w-[520px]`
															: ` max-w-[680px]`
													}`}
												>
													<h2 className="font-serif text-lg mb-2">
														{content.description}
													</h2>
													{content.link && (
														<Link
															className="text-sm text-grey-base link"
															to={content.link}
														>
															{content.linkText}
														</Link>
													)}
												</div>
											</div>
											<div
												className={`md:pb-10 md:border-grey-default box-border ${
													content.layout === "video" ? ` md:col-span-8` : ` `
												} ${
													content.layout === "two-thirds"
														? ` md:col-span-7`
														: ` `
												} ${
													content.layout === "one-quarter"
														? ` md:col-span-4`
														: ` `
												} ${
													content.imageDirection === "image-right"
														? ` order-2 md:border-l mt-10 md:mt-0`
														: ` order-1 md:pr-10 md:border-r`
												}`}
											>
												{content.media === "image" &&
													content.imageType === "landscape" && (
														<Image
															{...content.image}
															width={1000}
															height={680}
															alt={content.image.alt}
															className={`lazy ` + (loaded && " loaded")}
															onLoad={() => setLoaded(true)}
														/>
													)}
												{content.media === "image" &&
													content.imageType === "portrait" && (
														<Image
															{...content.image}
															width={605}
															height={880}
															alt={content.image.alt}
															className={
																`max-w-[75%] md:max-w-[90%] max-h-[880px] w-auto lazy ` +
																(loaded && " loaded")
															}
															onLoad={() => setLoaded(true)}
														/>
													)}
												{content.media === "video" && (
													<div
														className={
															`video-wrapper player-wrapper relative lazy ` +
															(loaded && " loaded")
														}
													>
														<ReactPlayer
															width="100%"
															height="100%"
															className="react-player"
															playing
															playsinline={true}
															loop
															muted={muted}
															volume={1}
															config={{
																file: {
																	attributes: {
																		autoPlay: true,
																		muted: true,
																	},
																},
															}}
															url={"https://vimeo.com/690350196/e6e8febf5c"}
														/>
														<MutedButton muted={muted} setMuted={setMuted} />
													</div>
												)}
											</div>
										</div>
									</div>
								</InView>
							)}

							{content._type === "fullBleedImage" && (
								<InView key={i}>
									<Image
										{...content.image}
										width={1400}
										height={770}
										alt={content.image.alt}
										className={`lazy ` + (loaded && " loaded")}
										onLoad={() => setLoaded(true)}
									/>
								</InView>
							)}

							{content._type === "relatedNews" && (
								<InView key={i}>
									<div className="border-b border-grey-default box-border">
										<div className="container flex flex-col md:grid md:grid-cols-12 md:gap-10 md:mt-10">
											<div className="md:col-span-12 my-5 md:my-0 md:pb-10">
												<h2>Our sustainability initiatives</h2>
											</div>
											<div className="hidden md:flex md:col-span-3 md:pb-10 md:border-r md:border-grey-default md:box-border"></div>
											{content.news.map((item) => (
												<div
													className="md:col-span-4 md:border-r md:border-grey-default md:box-border mb-10 md:mb-0"
													key={item._id}
												>
													<div className="md:pb-[120px] md:pr-10">
														{item.mainImage && item.mainImage.asset && (
															<Link
																className="overflow-hidden block"
																to={`/news/${item.slug.current}`}
															>
																<Image
																	{...item.mainImage}
																	width={600}
																	alt={item.mainImage.alt}
																	className={
																		`transition-transform duration-500 ease-in-out md:hover:transform md:hover:scale-[1.05] lazy ` +
																		(loaded && " loaded")
																	}
																	onLoad={() => setLoaded(true)}
																/>
															</Link>
														)}
														<h3 className="font-serif text-lg my-4 md:mt-10 md:mb-[15px]">
															{item.title}
														</h3>
														<div className="text-sm">
															{item._rawExcerpt && (
																<PortableText
																	content={item._rawExcerpt}
																	serializers={{
																		internalLink: ({ reference, children }) => {
																			const href = `/${reference._type}/${reference.slug.current}`;
																			return <Link to={href}>{children}</Link>;
																		},
																		link: ({ href, blank, children }) => {
																			return (
																				<a
																					href={href}
																					target={blank ? "_blank" : "_self"}
																					rel={blank ? "noreferrer" : undefined}
																				>
																					{children}
																				</a>
																			);
																		},
																	}}
																/>
															)}
														</div>
														<Link
															className="mt-3 text-sm text-grey-base link"
															to={`/news/${item.slug.current}`}
														>
															Read more
														</Link>
													</div>
												</div>
											))}
										</div>
									</div>
								</InView>
							)}

							{content._type === "relatedProjects" && (
								<InView key={i}>
									<div className="border-b border-grey-default box-border">
										<div className="container grid grid-cols-12 md:mt-10 md:gap-10 related-projects">
											<div className="col-span-12 mt-5 mb-12 md:my-0 md:pb-10">
												<h2>Our projects</h2>
											</div>
											<div className="hidden md:flex md:col-span-3 md:pb-10 md:border-r md:border-grey-default md:box-border">
												<Link
													className="text-sm text-grey-base link mt-2 md:mt-auto"
													to="/projects"
												>
													View all projects
												</Link>
											</div>
											{content.projects.map((item, index) => (
												<RelatedProject item={item} key={index} />
											))}
										</div>
									</div>
								</InView>
							)}

							{content._type === "environment" && (
								<InView key={i}>
									<div className="md:border-b md:border-grey-default md:box-border relative">
										<div className="container flex flex-wrap flex-start md:pt-10">
											<div className="md:w-1/2 md:sticky md:h-full md:top-[81px] md:pb-[25px] mb-[73px] md:mb-0">
												<h3 className="mt-5 mb-4 md:my-0">{content.title}</h3>
												<h2 className="font-serif text-lg max-w-[520px] md:mt-[120px] md:pr-10">
													{content.introduction}
												</h2>
											</div>
											<div className="md:w-1/2  md:mt-[150px]">
												<div className="flex flex-col md:pb-10 md:mr-[-30px] md:h-full md:border-l md:border-grey-default md:box-border">
													<div className="flex flex-col py-6 md:py-0 md:px-10 md:pb-10 md:mb-10 md:border-b md:border-grey-default md:box-border">
														<Sensor once>
															{({ isVisible }) => (
																<div
																	className={`animation animation--tree ${
																		isVisible && "animate"
																	}`}
																>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																</div>
															)}
														</Sensor>
														<div className="mt-auto max-w-[560px]">
															<h3 className="font-serif text-lg mb-3">
																{content.treesTitle}
															</h3>
															<p>{content.trees}</p>
														</div>
													</div>

													<span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>

													<div className="flex flex-col py-6 md:py-0 md:px-10 md:pb-10 md:mb-10 md:border-b md:border-grey-default md:box-border">
														<Sensor once>
															{({ isVisible }) => (
																<div
																	className={`animation animation--solar ${
																		isVisible && "animate"
																	}`}
																>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																</div>
															)}
														</Sensor>
														<div className="mt-auto max-w-[560px]">
															<h3 className="font-serif text-lg mb-3">
																{content.solarTitle}
															</h3>
															<p>{content.solar}</p>
														</div>
													</div>

													<span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>

													<div className="flex flex-col py-6 md:py-0 md:px-10 md:pb-10 md:mb-10 md:border-b md:border-grey-default md:box-border">
														<Sensor once>
															{({ isVisible }) => (
																<div
																	className={`animation animation--water ${
																		isVisible && "animate"
																	}`}
																>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																</div>
															)}
														</Sensor>
														<div className="mt-auto max-w-[560px]">
															<h3 className="font-serif text-lg mb-3">
																{content.waterTitle}
															</h3>
															<p>{content.water}</p>
														</div>
													</div>

													<span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>

													<div className="flex flex-col py-6 md:py-0 md:px-10 md:pb-10 md:mb-10 md:border-b md:border-grey-default md:box-border">
														<Sensor once>
															{({ isVisible }) => (
																<div
																	className={`animation animation--roofs ${
																		isVisible && "animate"
																	}`}
																>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																</div>
															)}
														</Sensor>
														<div className="mt-auto max-w-[560px]">
															<h3 className="font-serif text-lg mb-3">
																{content.roofsTitle}
															</h3>
															<p>{content.roofs}</p>
														</div>
													</div>

													<span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>

													<div className="flex flex-col py-6 md:py-0 md:px-10 md:pb-10 md:mb-10 md:border-b md:border-grey-default md:box-border">
														<Sensor once>
															{({ isVisible }) => (
																<div
																	className={`animation animation--liveable ${
																		isVisible && "animate"
																	}`}
																>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																</div>
															)}
														</Sensor>
														<div className="mt-auto max-w-[560px]">
															<h3 className="font-serif text-lg mb-3">
																{content.liveableTitle}
															</h3>
															<p>{content.liveable}</p>
														</div>
													</div>

													<span className="w-screen mx-[-20px] h-px bg-grey-default md:hidden"></span>

													<div className="flex flex-col py-6 md:py-0 md:px-10">
														<Sensor once>
															{({ isVisible }) => (
																<div
																	className={`animation animation--wheat ${
																		isVisible && "animate"
																	}`}
																>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																	<span className="delay-300"></span>
																</div>
															)}
														</Sensor>
														<div className="mt-auto max-w-[560px]">
															<h3 className="font-serif text-lg mb-3">
																{content.wheatTitle}
															</h3>
															<p>{content.wheat}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</InView>
							)}
						</>
					))}
			</Container>
		</>
	);
};

export default Sustainability;
